import { QueryFunctionContext, QueryKey } from '@tanstack/react-query';

import { fetchCountries } from '../../../services/countries';
import { getCountriesKey } from '../query-keys';

export const getCountries = (options: {
  locale: string;
}): [
  QueryKey,
  (options: QueryFunctionContext) => ReturnType<typeof fetchCountries>
] => [
  getCountriesKey(options),
  ({ queryKey: [, locale] }: { queryKey: string[] }): Promise<TCountry[]> =>
    fetchCountries({ locale }),
];

export default getCountries;
