import React, { Suspense, FunctionComponent } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { useCountryOptions } from '../../../../hooks/use-country-options';

import GenericSelectInput from './generic-select-input';

type Props = {
  label: string;
  placeholder?: string;
  id: string;
  error?: any;
  isLoading?: boolean;
  disabled?: boolean;
  register: UseFormRegisterReturn;
  options?: TCountry[];
};

const Input: FunctionComponent<Props> = ({
  label,
  placeholder,
  id,
  error,
  isLoading,
  disabled = false,
  register,
  options,
}) => {
  return (
    <GenericSelectInput
      {...register}
      label={label}
      id={id}
      placeholder={placeholder}
      disabled={isLoading || disabled}
      error={error}
      options={options?.map((option) => ({
        value: option.alpha2,
        text: option.name,
      }))}
    />
  );
};
export const GenericCountryInput: FunctionComponent<Props> = (props) => {
  const { data } = useCountryOptions();
  return <Input {...props} options={data} />;
};

const GenericCountrySuspense: FunctionComponent<Props> = (props) => {
  return (
    <Suspense fallback={<Input {...props} />}>
      <GenericCountryInput {...props} />
    </Suspense>
  );
};

export default GenericCountrySuspense;
