import { forwardRef } from 'react';

import GenericInputWrapper from './generic-wrapper/generic-input-wrapper';

export const GenericTextAreaInput = forwardRef((props, ref) => {
  return <GenericInputWrapper {...props} ref={ref} as="textarea" />;
});

GenericTextAreaInput.displayName = 'GenericTextAreaInput';

export default GenericTextAreaInput;
