import cx from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import { useCallback, useState, forwardRef, FunctionComponent } from 'react';

import Other from './other-input';
import Select from './select-input';

type TGenericInputWrapper = {
  as: 'input' | 'textarea' | 'select' | 'checkbox';
  label: string;
  onFocus?: Function;
  onBlur?: Function;
  id: string;
  error?: Array<string>;
  hiddenLabel?: boolean;
  required?: boolean;
} & HTMLInputElement &
  HTMLTextAreaElement &
  HTMLSelectElement;

const GenericInputWrapper: FunctionComponent = forwardRef<
  HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement,
  TGenericInputWrapper
>(
  (
    {
      as,
      label,
      onFocus,
      onBlur,
      id,
      error = [],
      hiddenLabel = false,
      disabled = false,
      required = true,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [isFocus, setFocus] = useState(false);
    const internalOnBlur = useCallback(
      (e) => {
        onBlur && onBlur(e);
        setFocus(false);
      },
      [onBlur]
    );
    const internalOnFocus = useCallback(
      (e) => {
        onFocus && onFocus(e);
        setFocus(true);
      },
      [onFocus]
    );
    const hasErrors = !!error.length;
    const isCheckBox = props.type === 'checkbox';

    const inputProps = {
      internalOnBlur,
      internalOnFocus,
      as,
      id,
      disabled,
      ...props,
    };

    const defaultClasses = [
      'grid',
      'border-b',
      'grid-cols-1',
      disabled
        ? 'bg-neutral-10 border-neutral-30'
        : hasErrors
        ? 'border-red-100'
        : isFocus
        ? 'border-brand-100'
        : 'border-neutral-30',
    ];

    const checkClasses = [
      'flex',
      'items-center',
      'justify-end',
      'flex-row-reverse',
      disabled ? 'bg-neutral-10' : '',
      ,
    ];

    return (
      <div className={cx('grid', 'grid-cols-1')}>
        <div
          className={cx(
            'px-4',
            'py-1',
            isCheckBox ? checkClasses : defaultClasses
          )}
        >
          <label
            className={cx(
              'text-xs',
              'leading-4',
              'text-xs',
              'font-bold',
              { 'text-neutral-50 pl-4': isCheckBox },
              { 'text-brand-100': isFocus },
              { 'text-red-100': hasErrors },
              hiddenLabel && 'invisible'
            )}
            htmlFor={id}
          >
            {label}
            {!required && (
              <>
                {' '}
                - <span className="text-brand-100">{t('forms:optional')}</span>
              </>
            )}
          </label>

          {as === 'select' ? (
            <Select
              ref={ref}
              {...inputProps}
              required={required}
              aria-label={hiddenLabel ? label : undefined}
            />
          ) : (
            <Other
              ref={ref}
              {...inputProps}
              aria-label={hiddenLabel ? label : undefined}
              hasError={hasErrors}
            />
          )}
        </div>
        {hasErrors && (
          <span
            className={cx(
              'px-4',
              'block',
              'text-red-100',
              'text-xs',
              'leading-3',
              'py-3'
            )}
          >
            {error}
          </span>
        )}
      </div>
    );
  }
);

GenericInputWrapper.displayName = 'GenericInputWrapper';

export default GenericInputWrapper;
