import { forwardRef } from 'react';

import GenericInputWrapper from './generic-wrapper/generic-input-wrapper';

const GenericTextInput = forwardRef((props, ref) => {
  return <GenericInputWrapper {...props} ref={ref} as="input" />;
});

GenericTextInput.displayName = 'GenericTextInput';

export default GenericTextInput;
