import { QueryFunctionContext, QueryKey } from '@tanstack/react-query';

import { fetchPhonePrefixs } from '../../../services/countries';
import { getCountriesPhonesKey } from '../query-keys';

const getCountriesPhones = (options: {
  locale: string;
}): [
  QueryKey,
  (options: QueryFunctionContext) => ReturnType<typeof fetchPhonePrefixs>
] => [
  getCountriesPhonesKey(options),
  ({ queryKey: [, locale] }: { queryKey: string[] }): Promise<TPhonePrefix[]> =>
    fetchPhonePrefixs({ locale }),
];

export default getCountriesPhones;
