// LEGACY
import { access } from 'fs';

import { useQuery } from '@tanstack/react-query';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import React, {
  Suspense,
  FunctionComponent,
  useCallback,
  useMemo,
} from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import getGroups from '../../../../utils/react-query/query-options/get-groups';
import Select, {
  CustomDownChevron,
  CustomOptionParts,
  CustomSingleValueParts,
  CustomMenuParts,
} from '../../../ui/inputs/custom-selector';

type Props = {
  label: string;
  placeholder?: string;
  id: string;
  error?: any;
  isLoading?: boolean;
  disabled?: boolean;
  register: UseFormRegisterReturn;
  options?: TCategories;
  value?: any;
  onChange?: (value: any) => void;
};

const Input: FunctionComponent<Props> = ({
  label,
  placeholder,
  id,
  error,
  isLoading,
  disabled = false,
  register,
  options,
  value,
  onChange,
}) => {
  const { t } = useTranslation();

  const optionParts = useCallback(
    (group) =>
      Object.values(group.parts).reduce((acc, arr) => [...acc, ...arr], []),
    []
  );

  return (
    <Select
      {...register}
      label={label}
      id={id}
      aria-label={label}
      openMenuOnFocus
      isLoading={isLoading}
      isDisabled={isLoading || disabled}
      loadingMessage={() => t('common:loading')}
      options={options?.map((option) => ({
        label: option.name,
        options: optionParts(option).map((item) => ({
          value: item.slug,
          label: item.name,
        })),
      }))}
      components={{
        Option: CustomOptionParts,
        SingleValue: CustomSingleValueParts,
        DropdownIndicator: CustomDownChevron,
        MenuList: CustomMenuParts,
      }}
      onChange={onChange}
      placeholder={placeholder}
      error={error}
    />
  );
};

export const GenericPartInput: FunctionComponent<Props> = (props) => {
  const { locale = 'en' } = useRouter();
  const { data, isLoading } = useQuery(...getGroups({ locale: locale }), {
    cacheTime: Infinity,
    suspense: true,
  });
  return <Input {...props} options={data} isLoading={isLoading} />;
};

const GenericPartSuspense: FunctionComponent<Props> = (props) => {
  return (
    <Suspense fallback={<Input {...props} />}>
      <GenericPartInput {...props} />
    </Suspense>
  );
};

export default GenericPartSuspense;
