import { QueryFunctionContext, QueryKey } from '@tanstack/react-query';

import { fetchCountriesShipping } from '../../../services/countries';
import { getCountriesShippingKey } from '../query-keys';

const getCountriesShipping = (options: {
  locale: string;
}): [
  QueryKey,
  (options: QueryFunctionContext) => ReturnType<typeof fetchCountriesShipping>
] => [
  getCountriesShippingKey(options),
  ({
    queryKey: [, locale],
  }: {
    queryKey: string[];
  }): Promise<Record<string, TCountryShipping>> =>
    fetchCountriesShipping({ locale }),
];

export default getCountriesShipping;
