import cx from 'classnames';
import { FunctionComponent } from 'react';
import {
  DetailedHTMLProps,
  FocusEventHandler,
  forwardRef,
  SelectHTMLAttributes,
  useRef,
} from 'react';

import Chevrelon from '../../../icons/cheverlon';

import classes from './generic-input-wrapper.module.css';

type TSelect = {
  as: 'select';
  placeholder: string;
  options: Array<{
    value: string;
    text: string;
  }>;
  internalOnFocus: FocusEventHandler<HTMLSelectElement>;
  internalOnBlur: FocusEventHandler<HTMLSelectElement>;
  hasError: boolean;
} & DetailedHTMLProps<
  SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
>;

const Select = forwardRef<HTMLSelectElement, TSelect>(
  (
    {
      as = 'select',
      placeholder,
      options,
      internalOnFocus,
      internalOnBlur,
      value,
      required = true,
      disabled = false,
      className = '',
      ...props
    },
    ref
  ) => {
    const selectRef = useRef(null);
    const targerRef = ref || selectRef;
    return (
      <span
        className={cx(
          classes.select,
          { [classes.disabled]: disabled },
          'relative'
        )}
      >
        <select
          value={value}
          ref={targerRef}
          required={false}
          onFocus={internalOnFocus}
          onBlur={internalOnBlur}
          className={cx(
            'flex-1',
            'focus:border-none',
            'focus:outline-none',
            'text-base',
            'bg-transparent',
            { 'text-neutral-80 text-neutral-70 ': disabled },
            'truncate',
            'cursor-pointer',
            ...className
          )}
          disabled={disabled}
          {...props}
        >
          <option value="" disabled={required} selected>
            {placeholder}
          </option>
          {options?.map(({ value: target, text }) => (
            <option key={target + text} value={target}>
              {text}
            </option>
          ))}
        </select>
      </span>
    );
  }
);

Select.displayName = 'Select';

export default Select;
