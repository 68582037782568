import React, { Suspense, FunctionComponent, ChangeEventHandler } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { useCountryShippingOptions } from '../../../../hooks/use-country-options';

import GenericSelectInput from './generic-select-input';

type Props = {
  label: string;
  placeholder?: string;
  id: string;
  error?: any;
  isLoading?: boolean;
  disabled?: boolean;
  register: UseFormRegisterReturn;
  options?: Record<string, TCountryShipping>;
  onChange?: ChangeEventHandler<HTMLSelectElement> | undefined;
};

const Input: FunctionComponent<Props> = ({
  label,
  placeholder,
  id,
  error,
  isLoading,
  disabled = false,
  register,
  options,
  onChange,
}) => {
  return (
    <GenericSelectInput
      {...register}
      label={label}
      id={id}
      placeholder={placeholder}
      disabled={isLoading || disabled}
      error={error}
      options={
        options
          ? Object.entries(options).map(([key, option]) => ({
              value: key,
              text: option.name,
            }))
          : []
      }
      onChange={onChange}
    />
  );
};
export const GenericCountryShippingInput: FunctionComponent<Props> = (
  props
) => {
  const { data } = useCountryShippingOptions();

  return <Input {...props} options={data} />;
};

const GenericCountryShippingSuspense: FunctionComponent<Props> = (props) => (
  <Suspense fallback={<Input {...props} />}>
    <GenericCountryShippingInput {...props} />
  </Suspense>
);

export default GenericCountryShippingSuspense;
