import cx from 'classnames';
import { ComponentProps, FocusEventHandler, forwardRef } from 'react';

type TOther = {
  as: 'input' | 'textarea';
  placeholder: string;
  options: Array<{
    value: string;
    text: string;
  }>;
  internalOnFocus: FocusEventHandler<HTMLInputElement> &
    FocusEventHandler<HTMLTextAreaElement>;
  internalOnBlur: FocusEventHandler<HTMLInputElement> &
    FocusEventHandler<HTMLTextAreaElement>;
  hasError: boolean;
} & ComponentProps<'input'> &
  ComponentProps<'textarea'>;

const Other = forwardRef<HTMLInputElement | HTMLTextAreaElement, TOther>(
  (
    {
      as: As,
      disabled = false,
      internalOnFocus,
      internalOnBlur,
      hasError = false,
      ...props
    },
    ref
  ) => {
    return (
      <As
        ref={ref}
        onFocus={internalOnFocus}
        onBlur={internalOnBlur}
        className={cx(
          'focus:border-none',
          'focus:outline-none',
          'text-base',
          'bg-transparent',
          { 'text-neutral-80 lg:text-neutral-70': disabled },
          { 'h-4 w-4': props.type === 'checkbox' },
          hasError
            ? 'placeholder-red-100'
            : 'focus:placeholder-brand-100 placeholder-neutral-50'
        )}
        disabled={disabled}
        {...props}
      />
    );
  }
);

Other.displayName = 'Other';

export default Other;
