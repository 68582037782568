import { IncomingMessage } from 'http';

import { fechFromBPartsApi } from './utils';

export const fetchCountries = async (
  {
    locale = 'en',
  }: {
    locale?: string;
  },
  req?: IncomingMessage
): Promise<TCountry[]> => {
  const data = await fechFromBPartsApi(`/${locale}/shop/countries`, {
    credentials: 'include',
    headers: req ? new Headers(req.headers as HeadersInit) : undefined,
  });

  return data.json();
};

export const fetchCountriesShipping = async ({
  locale = 'en',
}: {
  locale?: string;
}): Promise<Record<string, TCountryShipping>> => {
  const data = await fechFromBPartsApi(`/${locale}/shop/countries-shipping`, {
    credentials: 'include',
  });

  return data.json();
};

export const fetchPhonePrefixs = async ({
  locale = 'en',
}: {
  locale?: string;
}): Promise<TPhonePrefix[]> => {
  const data = await fechFromBPartsApi(`/${locale}/shop/countries-phones`, {
    credentials: 'include',
  });

  return data.json();
};
