import cx from "classnames";
import useTranslation from "next-translate/useTranslation";
import React, { Suspense, FunctionComponent } from "react";
import { UseFormRegister } from "react-hook-form";

import { useCountryPhonesOptions } from "../../../../hooks/use-country-options";

import classes from "./generic-phone-input.module.css";
import GenericSelectInput from "./generic-select-input";
import GenericTextInput from "./generic-text-input";

type Props = {
  prefix: string;
  errors?: any;
  isLoading?: boolean;
  disabled?: boolean;
  options?: TPhonePrefix[];
  required?: boolean;
  register: UseFormRegister<Record<string, any>>;
  prefixName?: boolean;
  columns?: string;
  gap?: string;
  isPayment?: boolean;
};

const Input: FunctionComponent<Props> = ({
  isLoading,
  disabled = false,
  register,
  options,
  prefix,
  errors,
  required = true,
  prefixName = false,
  columns = classes.phoneInput,
  gap = "gap-0.5",
  isPayment,
}) => {
  const { t } = useTranslation();
  return (
    <div className={cx("grid", "items-start", columns, { [gap]: !isPayment })}>
      <div>
        <GenericSelectInput
          {...register(`${prefixName ? prefix + "." : ""}phone.prefix`, {
            required: required ? t("forms:errors.required") : false,
          })}
          label={t("forms:phones.label")}
          id={`${prefix}-phone-prefix`}
          placeholder={t("forms:phones.prefixPlaceholder")}
          options={options?.map((option) => ({
            text: `${option.countries} (+${option.phone_prefix})`,
            value: option.phone_prefix,
          }))}
          disabled={isLoading || disabled}
          error={isPayment ? errors[prefix]?.message : errors?.prefix?.message}
          required={required}
        />
      </div>
      <div>
        <GenericTextInput
          {...register(`${prefixName ? prefix + "." : ""}phone.number`, {
            required: required ? t("forms:errors.required") : false,
            pattern: {
              value: /^[0-9]*$/i,
              message: t("forms:errors.invalidPhone"),
            },
          })}
          label={t("forms:phones.label")}
          hiddenLabel={true}
          type="phone"
          id={`${prefix}-phone-number`}
          placeholder={
            isPayment
              ? t("forms:phones.numberPlaceholderPayment")
              : t("forms:phones.numberPlaceholder")
          }
          disabled={isLoading || disabled}
          error={errors?.number?.message}
        />
      </div>
    </div>
  );
};

export const GenericPhoneInput: FunctionComponent<Props> = (props) => {
  const { data } = useCountryPhonesOptions();

  return <Input {...props} options={data} />;
};

const GenericPhoneInputSuspense: FunctionComponent<Props> = (props) => (
  <Suspense fallback={<Input {...props} isLoading />}>
    <GenericPhoneInput {...props} />
  </Suspense>
);

export default GenericPhoneInputSuspense;
