import { forwardRef } from 'react';

import GenericInputWrapper from './generic-wrapper/generic-input-wrapper';

const GenericCheckBoxInput = forwardRef((props, ref) => {
  return (
    <GenericInputWrapper {...props} ref={ref} as="input" type="checkbox" />
  );
});

GenericCheckBoxInput.displayName = 'GenericCheckBoxInput';

export default GenericCheckBoxInput;
