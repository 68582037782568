import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import getCountries from '../../utils/react-query/query-options/get-countries';
import getCountriesPhones from '../../utils/react-query/query-options/get-countries-phones';
import getCountriesShipping from '../../utils/react-query/query-options/get-countries-shipping';

export const useCountryOptions = () => {
  const { locale } = useRouter();
  const { data } = useQuery(...getCountries({ locale: locale || 'en' }), {
    cacheTime: Infinity,
    suspense: true,
  });

  return { data };
};

export const useCountryShippingOptions = () => {
  const { locale } = useRouter();
  const { data } = useQuery(
    ...getCountriesShipping({ locale: locale || 'en' }),
    { cacheTime: Infinity, suspense: true }
  );

  return { data };
};

export const useCountryPhonesOptions = () => {
  const { locale } = useRouter();
  const { data } = useQuery(...getCountriesPhones({ locale: locale || 'en' }), {
    cacheTime: Infinity,
    suspense: true,
  });

  return { data };
};
