import cx from 'classnames';
import { forwardRef } from 'react';

import GenericInputWrapper from './generic-wrapper/generic-input-wrapper';

const GenericSelectInput = forwardRef((props, ref) => {
  return <GenericInputWrapper {...props} as="select" ref={ref} />;
});

GenericSelectInput.displayName = 'GenericSelectInput';

export default GenericSelectInput;
